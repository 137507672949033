<script>
  import { Html5Qrcode } from "html5-qrcode";
  import File from "./File.svelte";
  let decodedValues = [];
  function decodeImageFiles(files) {
    files.forEach(decodeImageFile);
  }
  function decodeImageFile(imageFile) {
    if (!imageFile) {
      return;
    }
    const html5QrCode = new Html5Qrcode("reader");
    html5QrCode
      .scanFile(imageFile)
      .then((decodedText) => {
        decodedValues = decodedValues.concat(decodedText);
        console.log(decodedValues);
        html5QrCode.clear();
      })
      .catch((err) => {
        console.log(`Error scanning file. Reason: ${err}`);
      });
  }
</script>

<main>
  <div id="reader" class="hide" />
  <File className="fileComp" onAddFiles={decodeImageFiles} />
  <ul>
    {#each decodedValues as value, index (index)}
      <li>{value}</li>
    {/each}
  </ul>
</main>

<style>
  main {
    height: 100vh;
    overflow: hidden;
  }
  .hide {
    display: none;
  }
</style>
