<script>
  import { onMount } from "svelte";
  export let className;
  export let onAddFiles;
  let isDragOver = false;
  function handleAddFiles(fileList) {
    onAddFiles([...fileList]);
  }
  function handleFileChange(e) {
    if (e.target.files.length) {
      handleAddFiles(e.target.files);
    }
  }
  function handleDrop(e) {
    e.preventDefault();
    isDragOver = false;
    if (e.dataTransfer.files.length) {
      handleAddFiles(e.dataTransfer.files);
    }
  }
  function handleDragOver(e) {
    e.preventDefault();
  }
  function handleDragEnter(e) {
    isDragOver = true;
  }
  function handleMouseLeave(e) {
    isDragOver = false;
  }
  onMount(() => {
    document.body.addEventListener("paste", (e) => {
      if (e.clipboardData.files.length) {
        handleAddFiles(e.clipboardData.files);
      }
    });
  });
</script>

<main>
  <div
    class={className + " file-con " + (isDragOver ? "drag-over" : "")}
    on:drop={handleDrop}
    on:dragover={handleDragOver}
    on:dragenter={handleDragEnter}
    on:mouseleave={handleMouseLeave}
  >
    <label for="file">
      <input type="file" id="file" on:change={handleFileChange} />
      DROP, PASTE, PICK FILE
      <slot />
    </label>
  </div>
</main>

<style>
  .file-con {
    padding: 20px;
    border: 1px solid #ddd;
    margin: 40px;
    height: 200px;
  }
  .file-con label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
  }
  .file-con:hover {
    background: #f1f1f1;
  }
  .file-con input {
    display: none;
  }
</style>
